import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { Link, useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { getFluid } from "../util/helper";
import BackgroundImage from "gatsby-background-image";
import IndigoModal from "../components/indigo-modal";

import SOLUTIONS from "../data/solutions.json";

const SolutionAreas = [
	"All",
	"Ancillary",
	"Corporate",
	"Data",
	"Hosting",
	"Payment",
	"PSS Solution",
	"Revenue",
	"Security",
];

const SolutionTypes = ["All", "Cloud", "Consulting", "Development", "Product"];

const IndigoPage = () => {
	const data = useStaticQuery(graphql`
		query IndigoPageQuery {
			allFile(filter: { relativePath: { in: ["banner/indigo.jpg"] } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(quality: 100, maxWidth: 1920) {
								...GatsbyImageSharpFluid
								...GatsbyImageSharpFluidLimitPresentationSize
							}
						}
					}
				}
			}
		}
	`);

	const fluidBanner = getFluid(data.allFile.edges, "banner/indigo.jpg");

	/* Dropdown isOpen */
	const [isSolutionAreasOpen, setSolutionAreasIsOpen] = useState(false);
	const [isSolutionTypesOpen, setSolutionTypesIsOpen] = useState(false);

	const handleSolutionAreasClick = () => {
		setSolutionAreasIsOpen(!isSolutionAreasOpen);
	};

	const handleSolutionTypesClick = () => {
		setSolutionTypesIsOpen(!isSolutionTypesOpen);
	};

	const closeSolutionDropdowns = () => {
		setSolutionAreasIsOpen(false);
		setSolutionTypesIsOpen(false);
	};

	/* Dropdown Selected */
	const [selectedSolutionArea, setSolutionArea] = useState("All");

	function selectSolutionArea(AreaName) {
		setSolutionArea(AreaName);
	}

	const [selectedSolutionType, setSolutionType] = useState("All");

	function selectSolutionType(TypeName) {
		setSolutionType(TypeName);
	}

	function getSolutionsCount(): number {
		let selectedSolutions = 0;

		if (selectedSolutionArea === "All" && selectedSolutionType === "All") selectedSolutions = SOLUTIONS.length;
		else {
			for (const solution of SOLUTIONS) {
				if (selectedSolutionType === "All" && solution.area.includes(selectedSolutionArea)) selectedSolutions++;
				else if (selectedSolutionArea === "All" && solution.type === selectedSolutionType) selectedSolutions++;
				else if (solution.type === selectedSolutionType && solution.area.includes(selectedSolutionArea))
					selectedSolutions++;
			}
		}

		return selectedSolutions;
	}

	/* Modal */
	const [modalObject, setModalObject] = useState<any>({
		title: "",
		summary: "",
		area: [],
		type: "",
		references: [],
		description: {
			text: [],
			list: [],
		},
	});
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		ReactModal.setAppElement("#indigoPage");
	});

	return (
		<Layout>
			<SEO title="Indigo" />
			<div id="indigoPage" className="text-brand-grayt">
				{/* Banner */}
				<BackgroundImage
					id="banner"
					Tag="div"
					className="bg-gray-400 text-white bg-cover h-400px"
					fluid={fluidBanner}
				>
					<div className="container text-white h-400px pt-4">
						<div className="md:w-4/5 lg:w-2/3 xl:w-3/5 px-4 highlight">
							<h1 className="pt-2 sm:pt-20 text-3xl sm:text-4xl leading-tight font-bold">
								<span>Marketplace for Indigo Airlines</span>
							</h1>
							<p className="text-lg mt-4 lg:mt-8">
								DGITAL Labs is offerring exclusive solutions for Airlines related to Indigo Partners. We
								are experts with over 15 years of experience of airline IT management, operations and
								custom development.
							</p>
							<div className="mt-4">
								<a
									href={`mailto:bertalan.nagy@dgital.com`}
									className="hover:text-brand-gray4 inline-grid grid-cols-1"
								>
									<div className="text-2xl font-semibold">
										Inquire about our exclusive service here &#10230;
									</div>
									<div>
										<svg
											className="w-4 fill-current inline mr-1"
											viewBox="0 0 580 580"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M462,54.955L355.371,437.187l-135.92-128.842L353.388,167l-179.53,124.074L50,260.973L462,54.955z M202.992,332.528v124.517l58.738-67.927L202.992,332.528z" />
										</svg>
										<span className="text-sm font-semibold inline">bertalan.nagy@dgital.com</span>
									</div>
								</a>
							</div>
						</div>
					</div>
				</BackgroundImage>
				{/* Filters */}
				<div id="indigoFilters">
					<div className="container p-4 pt-8">
						<div className="relative inline-block text-left mr-4">
							<div className="mb-1">Solution Area</div>
							<span className="rounded-sm shadow-sm w-8">
								<button
									type="button"
									onClick={handleSolutionAreasClick}
									className="inline-flex justify-between w-32 sm:w-40 rounded-sm border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
									id="options-menu"
									aria-haspopup="true"
									aria-expanded="true"
								>
									{selectedSolutionArea}
									<svg className="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
										<path
											fillRule="evenodd"
											d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</button>
							</span>

							<div
								className={`${
									isSolutionAreasOpen ? "block" : "hidden"
								} origin-top-left absolute left-0 mt-2 w-32 sm:w-40 z-20 rounded-md shadow-lg `}
							>
								<div className="rounded-md bg-white shadow-xs">
									<div
										className="py-1"
										role="menu"
										aria-orientation="vertical"
										aria-labelledby="options-menu"
									>
										{SolutionAreas.map((solutionArea) => (
											<button
												onClick={() => {
													selectSolutionArea(solutionArea);
													handleSolutionAreasClick();
												}}
												key={solutionArea}
												className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 w-full text-left"
												role="menuitem"
											>
												{solutionArea}
											</button>
										))}
									</div>
								</div>
							</div>
						</div>
						<div className="relative inline-block text-left mr-4">
							<div className="mb-1">Solution Type</div>
							<span className="rounded-sm shadow-sm w-8">
								<button
									type="button"
									onClick={handleSolutionTypesClick}
									className="inline-flex justify-between w-32 sm:w-40 rounded-sm border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150"
									id="options-menu"
									aria-haspopup="true"
									aria-expanded="true"
								>
									{selectedSolutionType}
									<svg className="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
										<path
											fillRule="evenodd"
											d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</button>
							</span>

							<div
								className={`${
									isSolutionTypesOpen ? "block" : "hidden"
								} origin-top-left absolute left-0 mt-2 w-32 sm:w-40 z-20 rounded-md shadow-lg `}
							>
								<div className="rounded-md bg-white shadow-xs">
									<div
										className="py-1"
										role="menu"
										aria-orientation="vertical"
										aria-labelledby="options-menu"
									>
										{SolutionTypes.map((solutionType) => (
											<button
												onClick={() => {
													selectSolutionType(solutionType);
													handleSolutionTypesClick();
												}}
												key={solutionType}
												className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 w-full text-left"
												role="menuitem"
											>
												{solutionType}
											</button>
										))}
									</div>
								</div>
							</div>
						</div>
						<button
							className="text-brand-green focus:outline-none mt-2 sm:mt-0"
							onClick={() => {
								selectSolutionType("All");
								selectSolutionArea("All");
								closeSolutionDropdowns();
							}}
						>
							Reset Filters
						</button>
					</div>
				</div>
				{/* Modal */}
				<IndigoModal
					modalObject={modalObject}
					isOpen={isModalOpen}
					closeModal={(isOpen) => setIsModalOpen(isOpen)}
				/>
				{/* Solution List */}
				<div className="container p-4 pb-16">
					<h1 className="mb-4 text-3xl font-semibold">
						Solutions
						<div className="inline ml-2">({getSolutionsCount()})</div>
					</h1>
					<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 sm:gap-6">
						{SOLUTIONS.map((solution) => (
							<div
								className={`${
									selectedSolutionArea === "All" || solution.area.includes(selectedSolutionArea)
										? "block"
										: "hidden"
								} ${
									selectedSolutionType === "All" || selectedSolutionType === solution.type
										? "block"
										: "hidden"
								}
                                w-full h-full relative m-auto shadow-xl pt-6 px-4 pb-10`}
								key={solution.title}
							>
								<div className="flex justify-between mb-4">
									<div
										className={`${
											solution.type === "Product" ? "border-brand-blue" : "border-brand-green"
										} border-b`}
									>
										{solution.type}
									</div>
									<div className="text-brand-gray3 text-sm">By Dgital</div>
								</div>
								<div className="text-xl font-semibold mb-2">{solution.title}</div>
								<div className="text-brand-gray3 mb-4 text-sm">
									{solution.area.map((area) => (
										<span key={area} className="mr-2">
											{area}
										</span>
									))}
								</div>
								<div className="mb-4">{solution.summary}</div>
								<button
									onClick={() => {
										setIsModalOpen(true);
										setModalObject(solution);
									}}
									className={`${
										solution.type === "Product" ? "text-brand-blue" : "text-brand-green"
									} hover:text-brand-gray2 mb-4 mr-6 uppercase block absolute bottom-0 right-0 focus:outline-none`}
								>
									Read more &#10230;
								</button>
							</div>
						))}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default IndigoPage;
