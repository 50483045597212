import React from "react";
import ReactModal from "react-modal";
import { getFluid } from "../util/helper";
import { Link, useStaticQuery, graphql } from "gatsby";

import Image from "gatsby-image";

interface Solution {
    title: string,
    summary: string,
    area: string[],
    type: string,
    references: string[],
    description: {
        text: object[],
        list: string[],
    },
};

const IndigoModal = ({ isOpen, closeModal, modalObject }: {
    isOpen: boolean; closeModal: any; modalObject: Solution;
}) => {

    const data = useStaticQuery(graphql`
		query IndigoModalQuery {
			allFile(
				filter: {
					relativePath: {
						in: [
							"modal/enerjet.png"
							"modal/jetsmart.png"
							"modal/wizzair.png"
							"modal/wizztours.png"
						]
					}
				}
			) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(quality: 100, maxWidth: 500) {
								...GatsbyImageSharpFluid
								...GatsbyImageSharpFluidLimitPresentationSize
							}
						}
					}
				}
			}
		}
    `);

    function renderDescriptionText(paragraphBlock) {
        if (paragraphBlock.hasOwnProperty("title")) {
            return (
                <div className="sm:mr-2 mb-2 sm:mb-4 text-xs sm:text-sm">
                    <div className="sm:mr-2 mb-1 text-base sm:text-lg font-semibold">{paragraphBlock.title}</div>
                    <div>{paragraphBlock.paragraph}</div>
                </div>
            );
        } else if (paragraphBlock.hasOwnProperty("paragraph")) {
            return (
                <div className="sm:mr-2 mb-2 sm:mb-4 text-xs sm:text-sm">
                    <div>{paragraphBlock.paragraph}</div>
                </div>
            );
        } else return;
    };

    function renderDescriptionList() {
        if (modalObject.description.hasOwnProperty("list")) {
            return (
                modalObject.description.list.map((area) => (
                    <li key={area} className="text-xs sm:text-sm sm:pr-6 lg:pr-4">{area}</li>
                ))
            );
        } else return;
    };

    function renderModal() {
        if (modalObject !== undefined) {
            return (
                <div className="p-2 sm:px-8 sm:py-4">
                    <div className="flex justify-between mb-1 sm:mb-6">
                        <div className="inline border-b border-brand-green uppercase text-xs sm:text-sm">{modalObject.type}</div>
                        <button
                            className="text-xs sm:text-sm text-brand-green hover:text-brand-grayt focus:outline-none"
                            onClick={() => closeModal(false)}>
                            Close
                        </button>
                    </div>
                    <div className="font-bold text-lg sm:text-2xl mb-1 sm:mb-2">{modalObject.title}</div>
                    <div className="hidden sm:flex justify-between sm:mb-2">
                        <div className="mb-2 text-xs sm:text-sm">
                            {modalObject.area.map((area) => (
                                <span key={area} className="mr-2">{area}</span>
                            ))}
                        </div>
                        <div className="text-brand-gray3 mb-1 text-xs sm:text-sm">By DGITAL</div>
                    </div>
                    <div className="mb-0 sm:mb-2">
                        {modalObject.description.text.map((paragraphBlock, i) => (
                            <div key={i} className="mr-2">
                                {renderDescriptionText(paragraphBlock)}
                            </div>
                        ))}
                    </div>
                    <ul className="sm:pl-6 sm:grid grid-cols-3 list-disc list-inside sm:list-outside sm:leading-6">
                        {renderDescriptionList()}
                    </ul>
                    <div>
                        <div className={`${
                            modalObject.references.length === 0
                                ? "hidden"
                                : "inline sm:block"
                            } font-bold text-xs sm:text-sm mr-2 sm:mt-6 `}>
                            References:
                        </div>
                        <div className="mt-2 inline sm:block">
                            {modalObject.references.map((referenceImageName) => (
                                <div key={referenceImageName} className="mr-2 inline-block w-1/5 sm:w-24">
                                    <Image fluid={getFluid(data.allFile.edges, "modal/" + referenceImageName + ".png")} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="text-right mt-2 sm:mt-0 mr-2">
                        <a
                            href={`mailto:bertalan.nagy@dgital.com`}
                            className="sm:text-xl text-brand-green hover:text-brand-gray2 font-bold uppercase"
                        >
                            Contact us &#10230;
								</a>
                        <div>
                            <svg
                                className="w-4 h-4 fill-current hidden sm:inline"
                                viewBox="0 0 580 580"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M462,54.955L355.371,437.187l-135.92-128.842L353.388,167l-179.53,124.074L50,260.973L462,54.955z M202.992,332.528v124.517l58.738-67.927L202.992,332.528z" />
                            </svg>
                            <section className="text-xs hidden sm:inline">bertalan.nagy@dgital.com</section>
                        </div>
                    </div>
                </div>
            );
        } else {
            return;
        }
    }

    return (
        <ReactModal
            isOpen={isOpen}
            contentLabel="Solution Modal"
            overlayClassName="m-auto z-40"
            className="m-auto w-11/12 md:w-3/4 xl:w-1/2 max-w-screen-md inset-x-0 top-0 z-40 sm:top-90px z-40"
            onRequestClose={() => closeModal(false)}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            style={{
                overlay: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                },
                content: {
                    position: "relative",
                    bottom: "auto",
                    border: "1px solid #ccc",
                    background: "#fff",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch",
                    borderRadius: "4px",
                    outline: "none",
                    padding: "0px"
                }
            }}
        >
            <div>{renderModal()}</div>
        </ReactModal>
    );
};

export default IndigoModal;
